require('./bootstrap');

// Masonry
const $ = require('jquery');
const jQueryBridget = require('jquery-bridget');
const Masonry = require('masonry-layout');
const imagesLoaded = require('imagesloaded');

// make Masonry a jQuery plugin
jQueryBridget( 'masonry', Masonry, $ );
jQueryBridget( 'imagesloaded', imagesLoaded, $ );

require('./isotope.pkgd.min');
require('./ekko-lightbox');
require('./scripts');
require('./tempusdominus-bootstrap-4.min');