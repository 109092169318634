//Back-to-top Button

var scrollToTopButton = document.getElementById("back-to-top-button");

window.requestAnimFrame = (function(){
    return  window.requestAnimationFrame       ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame    ||
        function( callback ){
            window.setTimeout(callback, 1000 / 60);
        };
})();

window.onscroll = function() {displayScrollButton()};

function displayScrollButton() {
    if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
        scrollToTopButton.style.display = "block";
    } else {
        scrollToTopButton.style.display = "none";
    }
}

function scrollToY(scrollTargetY, speed, easing) {
    // scrollTargetY: the target scrollY property of the window
    // speed: time in pixels per second
    // easing: easing equation to use

    var scrollY = window.scrollY || document.documentElement.scrollTop,
        scrollTargetY = scrollTargetY || 0,
        speed = speed || 2000,
        easing = easing || 'easeOutSine',
        currentTime = 0;

    // min time .1, max time .8 seconds
    var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

    // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
    var easingEquations = {
        easeOutSine: function (pos) {
            return Math.sin(pos * (Math.PI / 2));
        },
        easeInOutSine: function (pos) {
            return (-0.5 * (Math.cos(Math.PI * pos) - 1));
        },
        easeInOutQuint: function (pos) {
            if ((pos /= 0.5) < 1) {
                return 0.5 * Math.pow(pos, 5);
            }
            return 0.5 * (Math.pow((pos - 2), 5) + 2);
        }
    };

    // add animation loop
    function tick() {
        currentTime += 1 / 60;

        var p = currentTime / time;
        var t = easingEquations[easing](p);

        if (p < 1) {
            requestAnimFrame(tick);

            window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
        } else {
            window.scrollTo(0, scrollTargetY);
        }
    }

    // call it once to get started
    tick();
}

scrollToTopButton.onclick = function(){scrollToY(0, 1500, 'easeInOutQuint')};




// Navbar

$(document).scroll(function () {
    setTimeout(function() {
        let url = window.location.pathname;

        if (url != '/campaigns' && url != '/contacts' && url != '/gallery') {

            const $nav = $(".fixed-top");
            const $langSelect = $(".lang-select .nav-link");
            const $logo = $(".navbar-brand svg");
            const $btnBookNow = $(".navbar .btn-book-now");
            const $navbarToggle = $(".btn-navbar-toggle");

            $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
            $langSelect.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
            $logo.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
            $btnBookNow.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
            $navbarToggle.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
        }
    },50, );
});




// SVG
$(function(){
    activate('img[src*=".svg"]');

    function activate(string){
        jQuery(string).each(function(){
            var $img = jQuery(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            jQuery.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Add replaced image's ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');
        });
    }
});




// Quotes swiper
const swiper1 = new Swiper ('.swiper1', {
    // Optional parameters
    slidesPerView: 1,
    spaceBetween: 30,
    centeredSlides: true,
    preventClicks: true,
    clickable: false,
    speed: 1000,
    loop: true,
    autoHeight: false,

    autoplay: {
        delay: 5000,
        disableOnInteraction: true,
    },

    pagination: {
        el: '.swiper-pagination',
    },

});





// Panels
$('.btn-collapse').on('click',function(e){
    if($(this).parents('.card').children('.collapse').hasClass('show')){
        e.stopPropagation();
    }
    e.preventDefault();
});




// Forms
$('.input100').each(function(){
    $(this).on('blur', function(){
        if($(this).val().trim() != "") {
            $(this).addClass('has-val');
        }
        else {
            $(this).removeClass('has-val');
        }
    })
});



// Rooms page filters
$(".filter-button").click(function () {

    let value = $(this).attr('data-filter');

    if (value == "all") {
        $('.filter').show('0');

    } else {
        $(".filter").not('.' + value).hide('0');
        $('.filter').filter('.' + value).show('0');
    }
});

if ($(".filter-button").removeClass("active")) {
    $(this).removeClass("active");
}






setTimeout(function(){
    $(".alert").fadeTo(2000, 500).slideUp(500, function(){
        $(".alert").slideUp(500);
    });
}, 5000 ); // 5 secs


